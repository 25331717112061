<!--
 * @Author: DY
 * @Date: 2020-08-15 14:27:02
 * @LastEditors: your name
 * @LastEditTime: 2022-07-20 11:16:17
 * @Describe:
-->
<template>
    <div class="iframe">
        <iframe
            frameborder="0"
            class="formIframe"
            :id="frameId"
            :src="formSrc"
        ></iframe>
    </div>
</template>

<script>
export default {
    // 选项卡嵌套表单页面
    name: 'form-page',
    data() {
        return {
            formSrc: '',
            frameId: '',
        };
    },
    props: {
        index: {
            type: [Array, Object, String, Number],
        },
        formData: {
            type: [Array, Object, String],
            required: true, // 是否必传
        },
        formUseInJyFlow: {
            type: [Boolean],
        },
        term: {
            type: [Array, Object, String, Number],
        },
        businessInstanceId: {
            type: [Array, Object, String],
        },
        currentBusinessProcessId: {
            type: [Array, Object, String],
        },
        tenantId: {
            type: [Array, Object, String],
        },
    },
    watch: {
        index() {
            this.splicingFormUrl();
        },
        formData() {
            this.splicingFormUrl();
        },
        deep: true,
    },
    created() {
        if (this.formData !== '') {
            this.splicingFormUrl();
        }
    },
    components: {
    },
    methods: {
        // 拼接表单地址
        splicingFormUrl() {
            if (this.index !== '' && typeof this.index !== 'undefined') {
                this.frameId = `frameId${this.index}`;
            } else {
                const thisFormOnly = this.$queryString(this.formData.FormUrl, 'FormCode');
                this.frameId = `frameId${thisFormOnly}`;
            }
            let formSrc = './../../angularForm/FormView.html?' + this.formData.FormUrl + '&frameId=' + this.frameId;
            if (this.formUseInJyFlow) {
                formSrc += '&FormUseInJyFlow=true';
            }
            if (this.term) {
                formSrc += `&${this.term}`;
            }
            if ((this.formData.FormKeyValue || '') !== '') {
                formSrc += `&PKValues=${this.formData.FormKeyValue}`;
            }
            if (this.formData.IsRead) {
                formSrc += `&IsView=${this.formData.IsRead}`;
            }
            if (this.businessInstanceId) {
                formSrc += `&businessInstanceId=${this.businessInstanceId}`;
            }
            if (this.currentBusinessProcessId) {
                formSrc += `&business_process_id=${this.currentBusinessProcessId}`;
            }
            if (this.tenantId) {
                formSrc += `&tenant_id=${this.tenantId}`;
            }
            this.formSrc = formSrc;
        },
    },
};
</script>

<style lang="stylus" scoped>
.iframe
    height 100%
    .formIframe
        width 100%
        height 100%
</style>
